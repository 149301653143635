export const handleChangeStandardGenerator = (state,setState) => (event) => {
    // This is a custom form handler that generates a function
    // binded to a specific form state
    // Note: The field name of the state should match the id
    const {id,name, value} = event.target;
    const newState = {...state, [id || name]:value};
    setState(newState);
};

export const handleCustomKeyChangeGenerator = (state,setState,key) => (value) => {
    // Use as an event with custom Dropdowns
    setState({ ...state, [key]: value });
};